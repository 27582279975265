import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Loading from 'components/Loading';
import { doc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMatching, selectMatching } from 'store/matchingSlice';
import { showMessage } from 'store/messageSlice';
import { fetchPlanning, savePlanning, updatePlanning } from 'store/planningSlice';
import { selectUser, selectedUserApplication } from 'store/userSlice';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';

const MentorPlanning = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userApplication = useSelector(selectedUserApplication);

  const [isEdit, setIsEdit] = useState(false);
  const [docData, setDocData] = useState(null);
  const [lessons, setLessons] = useState(Array(12).fill({}));

  // 배남프 현재 기수
  const { currentGeneration } = fetchRemoteConfigValues();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());

    const lessons = {};
    for (let i = 1; i <= 12; i++) {
      const date = data[`date${i}`];
      const title = data[`title${i}`];
      const memo = data[`memo${i}`];

      if (date || title || memo) {
        lessons[i] = {
          date,
          title,
          memo,
        };
      }

      delete data[`date${i}`];
      delete data[`title${i}`];
      delete data[`memo${i}`];
    }
    data.lessons = lessons;
    data.uid = user.uid;
    data.aid = userApplication.aid;
    data.generation = currentGeneration;

    if (isEdit) {
      const updateData = { ...data, docId: docData.docId };
      dispatch(updatePlanning(updateData)).then((result) => {
        if (result) {
          dispatch(showMessage({ message: '수업계획서가 저장되었습니다.', variant: 'success' }));
        }
      });
    } else {
      dispatch(savePlanning(data)).then((result) => {
        if (result) {
          dispatch(showMessage({ message: '수업계획서가 저장되었습니다.', variant: 'success' }));
        } else {
          dispatch(showMessage({ message: '수업계획서 저장에 실패하였습니다.', variant: 'error' }));
        }
      });
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(fetchPlanning(user.uid)).then((result) => {
        var object = {};

        if (result.payload && result.payload.lessons) {
          object = result.payload;
          const lessons = [];
          for (let i = 1; i <= 12; i++) {
            if (result.payload.lessons[i]) {
              lessons.push(result.payload.lessons[i]);
            } else {
              lessons.push({});
            }
          }
          setLessons(lessons);
          setIsEdit(true);
        }

        dispatch(fetchMatching(user.uid)).then((result) => {
          if (result.payload) {
            object = {
              ...object,
              subject: result.payload.subject,
              mentorName: userApplication.name,
              mentorContact: userApplication.contact,
            };
          }
          setDocData(object);
        });
      });
    }

    return () => {
      setDocData(null);
      setLessons(Array(12).fill({}));
      setIsEdit(false);
    };
  }, [dispatch, user]);

  if (!docData) {
    return <Loading />;
  }

  return (
    <Box className="flex flex-col justify-start px-10 py-10 md:px-10 md:py-10 my-4 md:my-2 w-full max-w-5xl box-border bg-white rounded-2xl">
      <Typography className="pb-2" variant="h2">
        수업계획서 작성하기
      </Typography>
      <Typography className="pb-8 !font-medium">
        멘티 매칭 이후 첫 만남을 가진 후에 멘티의 학업 진도를 고려하여 10주의 진행과정을
        작성해주시면 됩니다.
      </Typography>
      <Divider />
      <Box className="flex flex-col px-4 pt-8 gap-4">
        {docData && lessons && (
          <Grid container rowSpacing={3} columnSpacing={2} component="form" onSubmit={handleSubmit}>
            <Grid item xs={12} sm={6}>
              <Typography className="text-left !font-bold pb-2">수업 과목</Typography>
              <Box className="flex flex-row">
                <TextField
                  id="subject"
                  name="subject"
                  variant="outlined"
                  InputProps={{ className: 'p-1 !text-gray-500', color: 'error', readOnly: true }}
                  defaultValue={docData.subject}
                  hiddenLabel
                  size="small"
                />
                <TextField
                  id="subjectDetail"
                  name="subjectDetail"
                  variant="outlined"
                  InputProps={{ className: 'p-1', color: 'error' }}
                  placeholder=""
                  defaultValue={docData?.subjectDetail || ''}
                  hiddenLabel
                  size="small"
                  fullWidth
                  sx={{ ml: 1 }}
                  helperText="세부 과목이 있다면 자세히 적어주세요."
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="text-left !font-bold pb-2">멘토 이름 / 연락처</Typography>
              <Box className="flex flex-row">
                <TextField
                  id="mentorName"
                  name="mentorName"
                  variant="outlined"
                  InputProps={{ className: 'p-1 !text-gray-500', color: 'error', readOnly: true }}
                  defaultValue={docData?.mentorName}
                  hiddenLabel
                  size="small"
                  fullWidth
                />
                <TextField
                  id="mentorContact"
                  name="mentorContact"
                  variant="outlined"
                  InputProps={{ className: 'p-1 !text-gray-500', color: 'error', readOnly: true }}
                  defaultValue={docData?.mentorContact}
                  hiddenLabel
                  sx={{ ml: 1 }}
                  size="small"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="text-left !font-bold pb-2">멘티 거주 국가</Typography>
              <TextField
                id="menteeCountry"
                name="menteeCountry"
                variant="outlined"
                InputProps={{ className: 'p-1', color: 'error' }}
                placeholder=""
                defaultValue={docData?.menteeCountry || ''}
                hiddenLabel
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="text-left !font-bold pb-2">멘티 이름 / 연락처</Typography>
              <Box className="flex flex-row">
                <TextField
                  id="menteeName"
                  name="menteeName"
                  variant="outlined"
                  InputProps={{ className: 'p-1', color: 'error' }}
                  defaultValue={docData?.menteeName}
                  hiddenLabel
                  size="small"
                  fullWidth
                />
                <TextField
                  id="menteeContact"
                  name="menteeContact"
                  variant="outlined"
                  InputProps={{ className: 'p-1', color: 'error' }}
                  defaultValue={docData?.menteeContact || ''}
                  hiddenLabel
                  sx={{ ml: 1 }}
                  size="small"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-left !font-bold pb-2">
                수업 교재 (주 교재 / 부 교재)
              </Typography>
              <TextField
                id="textbook"
                name="textbook"
                variant="outlined"
                InputProps={{ className: 'p-1', color: 'error' }}
                placeholder=""
                defaultValue={docData?.textbook || ''}
                hiddenLabel
                size="small"
                fullWidth
                multiline
                rows={2}
                helperText="수업에 필요한 교재명을 정확한 출판사 명과 함께 적어주세요."
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-left !font-bold">수업일</Typography>
              <TextField
                id="lessonDT"
                name="lessonDT"
                variant="outlined"
                InputProps={{ className: 'p-1', color: 'error' }}
                placeholder=""
                defaultValue={docData?.lessonDT || ''}
                hiddenLabel
                size="small"
                fullWidth
                multiline
                rows={2}
                helperText="멘토와 의논 후 정한 수업일과 수업시간을 적어주세요. 한국과 시차가 발생 할 경우, 한국 시간과 현지 시간을 모두 적어주세요."
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-left !font-bold pb-2">멘티 특성</Typography>
              <TextField
                id="menteeCharacter"
                name="menteeCharacter"
                variant="outlined"
                InputProps={{ className: 'p-1', color: 'error' }}
                placeholder=""
                defaultValue={docData?.menteeCharacter || ''}
                hiddenLabel
                size="small"
                fullWidth
                multiline
                rows={3}
                helperText="멘티와의 첫 대면 후 멘티의 성격, 한국어 습득 정도, 초기 학습 상황 등을 짧게 적어주세요."
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className="text-left !font-bold pb-2">수업 목표</Typography>
              <TextField
                id="lessonGoal"
                name="lessonGoal"
                variant="outlined"
                InputProps={{ className: 'p-1', color: 'error' }}
                placeholder=""
                defaultValue={docData?.lessonGoal || ''}
                hiddenLabel
                size="small"
                fullWidth
                multiline
                rows={3}
                helperText="배남프 4기 수업을 통해 멘티가 도달해야 할 학습의 목표를 적어주세요."
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} square>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width={40}>
                        주차
                      </TableCell>
                      <TableCell align="center" width={140}>
                        날짜(시간)
                      </TableCell>
                      <TableCell align="center" width={300}>
                        학습 내용
                      </TableCell>
                      <TableCell align="center" width={120}>
                        비고
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lessons.map((lesson, index) => (
                      <TableRow key={index} sx={{ p: 1 }}>
                        <TableCell align="center" sx={{ p: 1 }}>
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" sx={{ p: 1 }}>
                          <TextField
                            id={`date${index + 1}`}
                            name={`date${index + 1}`}
                            variant="outlined"
                            InputProps={{ className: 'p-1 !text-sm', color: 'error' }}
                            placeholder="예시) O월 O일 (2h)"
                            defaultValue={lesson?.date || ''}
                            hiddenLabel
                            size="small"
                            fullWidth
                            sx={{ mr: 1 }}
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ p: 1 }}>
                          <TextField
                            id={`title${index + 1}`}
                            name={`title${index + 1}`}
                            variant="outlined"
                            InputProps={{ className: 'p-1 !text-sm', color: 'error' }}
                            placeholder="예시) 수업 소개 및 OT, 아이스브레이킹"
                            defaultValue={lesson?.title || ''}
                            hiddenLabel
                            size="small"
                            fullWidth
                          />
                        </TableCell>
                        <TableCell align="center" sx={{ p: 1 }}>
                          <TextField
                            id={`memo${index + 1}`}
                            name={`memo${index + 1}`}
                            variant="outlined"
                            InputProps={{ className: 'p-1 !text-sm', color: 'error' }}
                            placeholder=""
                            defaultValue={lesson?.memo || ''}
                            hiddenLabel
                            size="small"
                            fullWidth
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="outlined">
                저장하기
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default MentorPlanning;
