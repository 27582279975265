import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
  updateDoc,
  doc,
  setDoc,
} from 'firebase/firestore';
import { db } from 'firebase_config';

/// 배남프 현재 기수 정보
const GENERATION = 6;

const initialState = {
  data: null,
};

export const fetchPlanning = createAsyncThunk('planning/fetchPlanning', async (uid) => {
  const fetchRef = collection(db, 'Plannings');
  const queryRef = query(fetchRef, where('uid', '==', uid), where('generation', '==', GENERATION));

  const querySnapshot = await getDocs(queryRef);
  let planningData = {};

  if (querySnapshot.empty) {
    return null;
  }

  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      planningData = {
        ...doc.data(),
        docId: doc.id,
      };
    }
  });
  return planningData;
});

export const savePlanning = createAsyncThunk('matching/savePlanning', async (data) => {
  const newData = { ...data, createdAt: serverTimestamp() };
  try {
    const ref = doc(collection(db, 'Plannings'));
    newData['pid'] = ref.id;
    await setDoc(ref, newData, { merge: true });
  } catch (e) {
    // console.log(e);
  }
  // const docRef = await addDoc(collection(db, 'Plannings'), newData);

  return docRef.id;
});

export const updatePlanning = createAsyncThunk('matching/updatePlanning', async (data) => {
  const { docId, ...newData } = data;
  const docRef = await updateDoc(doc(db, 'Plannings', docId), newData);

  return docRef;
});

const planningSlice = createSlice({
  name: 'planning',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlanning.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(savePlanning.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const selectPlanning = (state) => state.planning.data;

export default planningSlice.reducer;
