import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { collection, getDocs, query, serverTimestamp, where } from 'firebase/firestore';
import { db } from 'firebase_config';
import applicationConverter from 'models/application_model';
import FirestorePath from 'services/firebase_path';
import { addDocument, getCollectionFuture } from 'services/firebase_services';

/// 배남프 현재 기수 정보
const GENERATION = 6;

const initialState = {
  data: {},
  mentor: {
    0: {
      type: 'mentor',
      name: '',
      contact: '',
      gender: '',
      birth: '',
      personality: {
        mentor: [],
        mentee: [],
      },
    },
    1: {
      availableSubjects: [],
      extraSubjects: '',
      availableCourses: [],
      availableDateTime: [],
      pledge: false, // 서약서 체크박스
    },
  },
  mentee: {
    0: {
      status: false,
      type: 'mentee',
      name: '',
      contact: '',
      birth: '',
      gender: '',
      belong: '',
      extraBelong: '',
      personality: {
        mentor: [],
        mentee: [],
      },
      availableSubjects: [],
      extraSubjects: '',
      availableCourses: [],
      availableDateTime: [],
      photoUrl: '', // 멘티사진
      prayerRequest: [], // 기도제목
      pledge: false, // 서약서 체크박스
    },
  },
};

export const saveApplication = createAsyncThunk(
  'application/saveApplication',
  async ({ data, type }) => {
    // type: mentor, mentee
    // data: application data
    const newData = { ...data, createdAt: serverTimestamp() };

    // DB에 저장
    const docId = await addDocument(FirestorePath.applications(), newData, 'aid', true);
    return docId;
  }
);

export const fetchApplication = createAsyncThunk('application/fetchApplication', async (uid) => {
  // DB에서 가져오기
  const fetchRef = collection(db, 'Applications');
  const queryRef = query(fetchRef, where('uid', '==', uid));
  const querySnapshot = await getDocs(queryRef);
  let applicationData;
  querySnapshot.forEach((d) => {
    const newData = {
      ...d.data(),
      // docId: d.id,
      createdAt: dayjs.unix(d.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    };
    applicationData = newData;
  });
  return applicationData;
});

export const fetchMenteeApplication = createAsyncThunk(
  'application/fetchMenteeApplication',
  async ({ subject, courses }) => {
    const docs = await getCollectionFuture(
      FirestorePath.applications(),
      (data, docId) => applicationConverter.fromFirestore(data),
      [
        where('userType', '==', 'mentee'),
        // where('matchingStatus', '<', 2),
        where('availableSubjects', 'array-contains', subject),
        where('generation', '==', GENERATION),
        // where('availableCourses', 'array-contains-any', courses),
      ]
    );
    if (docs.length === 0) {
      // console.log('no data');
    } else {
      // console.log('do something');
      // console.log(docs);
    }

    const temp =
      process.env.NODE_ENV === 'development'
        ? docs
        : // 실 환경에서 테스트 계정 멘티는 지워주기
          docs.filter(
            (doc) =>
              doc.email !== 'bhgt9510@naver.com' &&
              doc.email !== 'bhgt4563@gmail.com' &&
              doc.email !== 'cwcw0908@gmail.com' &&
              doc.email !== 'ljeongmin0908@gmail.com'
          );

    // 해당 과목 matchingStatus 확인하고 필터링 해주기
    const tttt = temp.filter((tem) => {
      return tem.matchingStatusBySubject[subject] < 2;
    });

    // course 일치 여부 쿼리가 안돼서 로컬에서 분류해서 일치하는 docs 뒤로 붙여주기
    const courseMatchList = tttt.filter((d) => courses.some((c) => d.availableCourses.includes(c)));
    const extraList = tttt.filter((d) => !courseMatchList.includes(d));

    // querySnapshot.forEach((d) => {
    //   if (d.data().availableSubjects.includes(subject)) {
    //     const newData = {
    //       ...d.data(),
    //       // docId: d.id,
    //       createdAt: dayjs.unix(d.data().createdAt.seconds).format('YYYY-MM-DD HH:mm:ss'),
    //     };
    //     applicationData.push(newData);
    //   }
    // });
    return [...courseMatchList, ...extraList];
  }
);

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplication: (state, action) => {
      const { step, data, type } = action.payload;
      Object.entries(data).forEach(([key, value]) => {
        state[type][step][key] = value;
      });
    },
    setInit: (state, action) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplication.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchMenteeApplication.fulfilled, (state, action) => {
        state.matchingData = action.payload;
      });
  },
});

export const selectApplication = (state) => state.application;
export const selectApplicationData = (state) => state.application.data;
export const selectMatchingData = (state) => state.application.matchingData;
export const { setApplication, setInit } = applicationSlice.actions;
export default applicationSlice.reducer;
