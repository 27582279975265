import { Box, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import { remoteConfig, getValue } from 'firebase_config';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';

const SomethingWrong = (props) => {
  const { isMentor } = props;
  const emojiList = [
    '🎁',
    '🎀',
    '🧸',
    '🎉',
    '🎈',
    '🪩',
    '🇰🇷',
    '⚽️',
    '🎲',
    '♟️',
    '🎸',
    '🚴',
    '🛹',
    '🛝',
    '🥋',
    '☂️',
    '🌸',
    '✨',
    '⭐️',
    '🌼',
    '🍄',
    '🍀',
  ];

  // 배남프 현재 기수
  const { currentGeneration } = fetchRemoteConfigValues();

  return (
    <Box className="flex flex-col flex-1 justify-center items-center text-center">
      <Typography variant="h1" fontSize="4rem">
        {emojiList[Math.floor(Math.random() * emojiList.length)]}
      </Typography>
      {isMentor ? (
        <Box>
          <Typography variant="h1">
            배남프 {currentGeneration}기 매칭이 종료되었습니다 :)
            <br />
          </Typography>
          <Typography variant="h2" fontWeight={'regular'}>
            매칭이 종료되어 이후 직접매칭을 통해 멘티를 연결해 드립니다.
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="h1">
            배남프 {currentGeneration}기 매칭이 종료되었습니다 :)
            <br />
            <br />
          </Typography>
          <Typography variant="h2" fontWeight={'regular'}>
            매칭이 종료되어 이후 직접매칭을 통해 멘토를 연결해 드립니다.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SomethingWrong;
