import { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import InfoBox from 'pages/my_page/components/InfoBox';
import styled from 'styled-components';
import withRouter from 'utils/withRouter';

import { remoteConfig, getValue } from 'firebase_config';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';

const MentorMatching = (props) => {
  const { application, isLoading, matchingStatus } = props;

  const [subject, setSubject] = useState('');

  // 배남프 현재 기수
  const { currentGeneration } = fetchRemoteConfigValues();

  const handleChangeSubject = (event) => {
    setSubject(event.target.value);
  };

  if (isLoading) {
    return (
      <Box className="h-96 flex justify-center items-center">
        <img src="/images/loading.gif" alt="loading..." width={100} />
      </Box>
    );
  }

  return (
    <InfoBox title="매칭하기">
      <Box>
        <Box>
          <Typography align="center">
            📌
            <br />
            배남프 {currentGeneration}기에서는
            <br />
            멘토 한 분이 한 명의 멘티와 만나 하나의 멘토링을 진행하게 됩니다!
            <br />
            신청했던 과목 중 하나를 결정하셔서 매칭 신청하기 버튼을 눌러주세요 :)
          </Typography>
        </Box>
        <Box className="pt-4 flex flex-row justify-center items-center">
          <FormControl sx={{ minWidth: 220 }}>
            <InputLabel id="subjects-label" color="error">
              과목
            </InputLabel>
            <Select
              labelId="subjects-label"
              id="subjects"
              value={subject}
              onChange={handleChangeSubject}
              displayEmpty
              label="과목"
              color="error"
              disabled={matchingStatus !== 0}
            >
              {application &&
                application?.availableSubjects
                  .filter((s) => s !== '기타')
                  .map((subject, index) => (
                    <MenuItem key={index} value={subject}>
                      {subject}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <Button
            onClick={() =>
              props.navigate('/matching/mentor', {
                state: { subject, courses: application.availableCourses },
              })
            }
            className={matchingStatus !== 0 ? '!bg-gray-300 !text-gray-700' : ''}
            disabled={matchingStatus !== 0}
          >
            {matchingStatus !== 0 ? '신청완료' : '멘티 선택하기'}
          </Button>
        </Box>
        {matchingStatus !== 0 ? (
          <Box className="flex w-full justify-center py-4">
            <Typography align="center" variant="body3" color={(theme) => theme.palette.error.main}>
              배남프 본부를 통해 매칭이 승인되면 매칭된 멘티 상세 정보가 보여집니다
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </InfoBox>
  );
};

const Button = styled.button`
  height: 56px;
  padding: 16px;
  background-color: #e4782a;
  border-radius: 8px;
  color: white;
  margin-left: 16px;
`;

export default withRouter(MentorMatching);
