import { Box, Divider } from '@mui/material';
import InfoBox from '../components/InfoBox';
import Waiting from '../components/Waiting';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectedUserApplication } from 'store/userSlice';
import FirestorePath from 'services/firebase_path';
import MyTitle from '../components/MyTitle';
import TimeBlocks from './components/TimeBlocks';
import Info from './components/Info';
import matchingConverter from 'models/matching_model';
import { getCollectionStream } from 'services/firebase_services';
import { orderBy, where } from 'firebase/firestore';
import withRouter from 'utils/withRouter';
import SomethingWrong from '../components/SomethingWrong';
import MentorMatching from './components/MentorMatching';
import Vacation from '../components/Vacation';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';
import BeforeBnp from '../components/BeforeBnp';

/// [기간별 구분]
/// 매칭 시작 ~ 매칭 끝: mentor-matching page, mentee-waiting page
/// 매칭 끝 ~ 배남프 전체 끝: something wrong page
/// 나머지 기간: vacation page

const Matching = () => {
  const user = useSelector(selectUser);
  const userApplication = useSelector(selectedUserApplication);

  const [matchingList, setMatchingList] = useState([]);
  const [approvedMentee, setApprovedMentee] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  // firebase remote config 데이터 가져오기
  const {
    currentGeneration,
    openMatchingDate,
    closeMatchingDate,
    startGenerationDate,
    endGenerationDate,
  } = fetchRemoteConfigValues();

  // const test = new Date();
  const test = new Date('2024-09-25 19:34:01');
  const today = test;

  // 배남프 현재 기수(N기) 멘토 매칭 선택 시작 date
  const openDay = new Date(openMatchingDate);
  // const openDay = new Date('2024-09-10 19:01:00');

  // 배남프 현재 기수(N기) 멘토 매칭 선택 종료 date
  const closeDay = new Date(closeMatchingDate);
  // const closeDay = new Date('2024-09-08 19:03:00');

  // 배남프 현재 기수(N기) 전체 시작 date
  const bnpStartDay = new Date(startGenerationDate);
  // const bnpStartDay = new Date('2024-09-09 00:00:00');

  // 배남프 현재 기수(N기) 전체 종료 date
  const bnpEndDay = new Date(endGenerationDate);
  // const bnpEndDay = new Date('2024-12-31 23:59:59');

  const isBeforeMatchingStart = bnpStartDay - today <= 0 && today - openDay < 0;
  const isInMatchingPeriod = today - openDay >= 0 && today - closeDay <= 0;
  const isBetweenGenerations = closeDay - today < 0 && today - bnpEndDay <= 0;

  // setCurrentGeneration(currentGeneration);
  // setIsBeforeMatchingStart(bnpStartDay - today <= 0 && today - openDay < 0);
  // setIsInMatchingPeriod(today - openDay >= 0 && today - closeDay <= 0);
  // setIsBetweenGenerations(closeDay - today < 0 && today - bnpEndDay <= 0);

  // // 배남프 현재 기수
  // const [currentGeneration, setCurrentGeneration] = useState(0);

  // // 오늘이 배남프 현재 기수(N기) 시작 ~ 매칭 시작 전인지 여부
  // const [isBeforeMatchingStart, setIsBeforeMatchingStart] = useState(false);
  // // 오늘이 배남프 현재 기수(N기) 매칭기간 중인지 여부
  // const [isInMatchingPeriod, setIsInMatchingPeriod] = useState(false);
  // // 오늘이 배남프 현재 기수(N기) 매칭 종료 ~ 현재 기수 배남프 전체 종료 사이인지 여부
  // const [isBetweenGenerations, setIsBetweenGenerations] = useState(false);

  const subjectIcon = {
    국어: '📚',
    영어: '🇺🇸',
    수학: '🧮',
    사회: '🌏',
    과학: '🧬',
    역사: '🛖',
    기타: '🎸',
  };

  // useEffect(() => {
  //   const fetchConfig = async () => {
  //     // const {
  //     //   currentGeneration,
  //     //   openMatchingDate,
  //     //   closeMatchingDate,
  //     //   startGenerationDate,
  //     //   endGenerationDate,
  //     // } = await fetchRemoteConfigValues();
  //     const currentGeneration = getNumber(remoteConfig, 'current_generation');
  //     const openMatchingDate = getString(remoteConfig, 'open_datetime_matching');
  //     const closeMatchingDate = getString(remoteConfig, 'close_datetime_matching');
  //     const startGenerationDate = getString(remoteConfig, 'start_current_generation');
  //     const endGenerationDate = getString(remoteConfig, 'end_current_generation');

  //     // const test = new Date();
  //     const test = new Date('2024-09-25 19:34:01');
  //     const today = test;

  //     // 배남프 현재 기수(N기) 멘토 매칭 선택 시작 date
  //     const openDay = new Date(openMatchingDate);
  //     // const openDay = new Date('2024-09-10 19:01:00');

  //     // 배남프 현재 기수(N기) 멘토 매칭 선택 종료 date
  //     const closeDay = new Date(closeMatchingDate);
  //     // const closeDay = new Date('2024-09-08 19:03:00');

  //     // 배남프 현재 기수(N기) 전체 시작 date
  //     const bnpStartDay = new Date(startGenerationDate);
  //     // const bnpStartDay = new Date('2024-09-09 00:00:00');

  //     // 배남프 현재 기수(N기) 전체 종료 date
  //     const bnpEndDay = new Date(endGenerationDate);
  //     // const bnpEndDay = new Date('2024-12-31 23:59:59');

  //     setCurrentGeneration(currentGeneration);
  //     setIsBeforeMatchingStart(bnpStartDay - today <= 0 && today - openDay < 0);
  //     setIsInMatchingPeriod(today - openDay >= 0 && today - closeDay <= 0);
  //     setIsBetweenGenerations(closeDay - today < 0 && today - bnpEndDay <= 0);
  //   };

  //   fetchConfig();
  // }, []);

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (openDay <= today) {
  //     setIsLoading(false);
  //   }
  // }, [openDay, today]);

  useEffect(() => {
    if (user && (isBeforeMatchingStart || isInMatchingPeriod || isBetweenGenerations)) {
      // matching 가져와서 업데이트해주기
      async function updateMatching() {
        const unsubscribe = await getCollectionStream(
          FirestorePath.matchings(),
          (querySnapshot) => {
            if (!querySnapshot.empty) {
              const docs = [];
              querySnapshot.forEach((doc) => {
                docs.push(matchingConverter.fromFirestore(doc));
              });

              // querySnapshot.docChanges().forEach((change) => {
              //   if (change.type === 'added') {
              //     console.log('New city: ', change.doc.data());
              //   }
              //   if (change.type === 'modified') {
              //     console.log('Modified city: ', change.doc.data());
              //   }
              //   if (change.type === 'removed') {
              //     console.log('Removed city: ', change.doc.data());
              //   }
              // });
              setMatchingList(docs);

              let mentee = [];
              docs.map((d) => {
                const approved = d.approvedMentee;
                const menteeList = d.menteeList;
                if (user.userType === 'mentor') {
                  mentee = [menteeList[approved]];
                } else {
                  mentee.push({
                    aid: d.mentorAid,
                    birth: d.mentorBirth,
                    contact: d.mentorContact,
                    availableCourses: d.mentorCourses,
                    availableDateTime: d.mentorDateTime,
                    email: d.mentorEmail,
                    extraSubjects: d.mentorExtraSubjects,
                    gender: d.mentorGender,
                    name: d.mentorName,
                    personality: d.mentorPersonality,
                    availableSubjects: d.mentorSubjects,
                    uid: d.mentorUid,
                    userType: d.userType,
                  });
                }
              });
              setApprovedMentee(mentee);
            }
          },
          [
            user.userType === 'mentor'
              ? where('mentorUid', '==', user.uid)
              : // where('mentorUid', '==', 'hRlf0Xd9mFVLGO7sm5g14fp7Ivu2')
                where('approvedMentee', '==', userApplication.aid),
            where('state', '==', 1),
            where('generation', '==', currentGeneration),
            orderBy('appliedAt', 'desc'),
          ]
        );
        return unsubscribe;
      }

      updateMatching();
    }
  }, [user, setMatchingList, userApplication]);

  let rederContent;

  if (!userApplication) {
    rederContent = <Waiting />;
  } else {
    rederContent = (
      <Box className="flex flex-col justify-start items-center w-full px-4 py-10">
        <MyTitle title="매칭" />
        {matchingList.length ? (
          // 매칭후(멘티, 멘토)
          <InfoBox title="매칭 상세보기">
            <Box>{user.userType === 'mentor' ? '멘티(학생)' : '멘토(선생님)'} 정보</Box>
            {matchingList
              .slice(0, user.userType === 'mentor' ? 1 : matchingList.length)
              .map((matching, index) => (
                <Box
                  key={index}
                  className="flex flex-col justify-center w-full border rounded-xl box-border"
                >
                  <Box className="flex w-full justify-start py-5 px-8">
                    {subjectIcon[matching.subject.slice(0, 2)]} {matching.subject}
                  </Box>
                  <Divider />
                  <Box className="flex flex-col p-8 gap-4">
                    <Info partner={approvedMentee[index]} contactVisible={true} />
                    <TimeBlocks mentee={approvedMentee[index]} mentor={userApplication} />
                  </Box>
                </Box>
              ))}
          </InfoBox>
        ) : isBeforeMatchingStart ? (
          <BeforeBnp />
        ) : isInMatchingPeriod ? (
          // 매칭전(멘티, 멘토)
          // 내 정보 페이지 - 매칭 페이지(매칭 신청 전)
          <>
            {user.userType === 'mentor' || user.userType === 'admin' ? (
              // 매칭전(멘토)
              <MentorMatching
                application={userApplication}
                isLoading={isLoading}
                matchingStatus={userApplication.matchingStatus}
              />
            ) : (
              // 매칭전(멘티)
              <Waiting />
            )}
          </>
        ) : isBetweenGenerations ? (
          <SomethingWrong isMentor={user.userType === 'mentor' || user.userType === 'admin'} />
        ) : (
          <Vacation />
        )}
      </Box>
    );
  }
  return rederContent;
};

export default withRouter(Matching);
