import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Stepper from 'components/applications/Stepper';
import MenteeInfo from 'components/applications/mentee/MenteeInfo';
import MenteeApply from 'components/applications/mentee/MenteeApply';
import { useSelector } from 'react-redux';
import withRouter from 'utils/withRouter';
import { selectUser, getCheckMentoringInfoExist } from 'store/userSlice';

const Mentee = (props) => {
  const user = useSelector(selectUser);
  const checkApplicationExist = useSelector(getCheckMentoringInfoExist);

  const [activeStep, setActiveStep] = useState(
    !checkApplicationExist || user.userType === 'mentee' ? 0 : 1
  );
  const stepStatus = useSelector((state) => state.application.mentee[activeStep]);

  const handlePrevStep = () => {
    if (activeStep === 0) {
      props.navigate(-1);
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const stepComponents = {
    0: (
      <MenteeInfo
        rows={stepStatus}
        step={activeStep}
        setStep={setActiveStep}
        actionPrevStep={handlePrevStep}
      />
    ),
    1: <MenteeApply rows={stepStatus} step={activeStep} actionPrevStep={handlePrevStep} />,
  };

  return (
    <Box className="m-auto w-full">
      <Box className="p-12 pb-0 md:p-16 md:pb-0">
        <Stepper steps={['멘티 정보', '신청 완료']} active={activeStep} />
      </Box>
      <Box className="p-12 md:p-16 text-center box-border">{stepComponents[activeStep]}</Box>
    </Box>
  );
};

export default withRouter(Mentee);
