import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import MyInfo from '../components/MyInfo';
import withRouter from 'utils/withRouter';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedApplicationIndex,
  logout,
  selectUserApplication,
  selectedUserApplication,
  updateUserApplications,
} from 'store/userSlice';
import { firebaseAuth } from 'firebase_config';
import { getCollectionStream } from 'services/firebase_services';
import FirestorePath from 'services/firebase_path';
import applicationConverter from 'models/application_model';
import { orderBy, where } from 'firebase/firestore';
import InfoBox from '../components/InfoBox';
import MyTitle from '../components/MyTitle';
import MentorLessonLog from './MentorLessonLog';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';

const BasicInfo = (props) => {
  const dispatch = useDispatch();

  const [userType, setUserType] = useState('');
  const [applicationList, setApplicationList] = useState([]);
  const selectedApplicationIndex = useSelector(getSelectedApplicationIndex);
  const { user } = props;

  // 배남프 현재 기수
  const [currentGeneration, setCurrentGeneration] = useState(0);

  useEffect(() => {
    if (user) {
      if (user.userType === 'mentor') {
        setUserType('멘토');
      } else if (user.userType === 'mentee') {
        setUserType('멘티');
      } else if (user.userType === 'admin') {
        setUserType('관리자');
      } else {
        setUserType('누구시죠');
      }

      // application 가져와서 업데이트해주기
      async function updateApplication() {
        // 배남프 현재 기수 가져오기
        const { currentGeneration } = await fetchRemoteConfigValues();
        setCurrentGeneration(currentGeneration);

        const unsubscribe = await getCollectionStream(
          FirestorePath.applications(),
          (querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
              docs.push(applicationConverter.fromFirestore(doc));
            });

            setApplicationList(docs);
            dispatch(updateUserApplications(docs));
          },
          [
            where('uid', '==', user.uid),
            where('generation', '==', currentGeneration),
            orderBy('createdAt', 'desc'),
          ]
        );
        return unsubscribe;
      }

      updateApplication();
    }
  }, [user, setApplicationList]);

  const logoutOfApp = () => {
    // dispatch to the store with the logout action
    dispatch(logout());
    // sign out function from firebase
    firebaseAuth.signOut();
    props.navigate('/signin');
  };

  return (
    <Box className="flex flex-col justify-start items-center w-full px-4 py-10">
      <MyTitle title={`내 정보 (${userType})`} />
      <InfoBox title="기본정보">
        {applicationList
          .slice(0 + selectedApplicationIndex, 1 + selectedApplicationIndex)
          .map((application, index) => (
            <MyInfo key={index} application={application} />
          ))}
        {user.userType === 'mentee' ? (
          <Box className="flex justify-center pt-4">
            <Button
              variant="text"
              sx={{ fontWeight: 'bold' }}
              onClick={() => props.navigate('/mentoring-info')}
            >
              + 멘티 정보 추가 입력하기
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </InfoBox>
      {(user.userType === 'mentor' || user.userType === 'admin') && <MentorLessonLog />}
      <Box className="flex justify-end w-full max-w-5xl box-border pb-16">
        <Button
          variant="text"
          onClick={() => props.navigate('/reset-password')}
          sx={{ color: 'black' }}
        >
          비밀번호변경
        </Button>
        <Button
          variant="text"
          onClick={logoutOfApp}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          로그아웃
        </Button>
      </Box>
    </Box>
  );
};

export default withRouter(BasicInfo);
