import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Root from 'components/Root';
import MyTitle from 'pages/my_page/components/MyTitle';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { fetchMenteeApplication, selectMatchingData } from 'store/applicationSlice';
import styled from 'styled-components';
import MenteeInfoDialog from './MenteeInfoDialog';
import { selectUser, selectedUserApplication } from 'store/userSlice';
import applicationConverter from 'models/application_model';
import { increment, serverTimestamp } from 'firebase/firestore';
import { addDocument, setDocument } from 'services/firebase_services';
import FirestorePath from 'services/firebase_path';
import matchingConverter from 'models/matching_model';
import withRouter from 'utils/withRouter';
import Loading from 'components/Loading';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';

const maskMiddleCharacter = (text) => {
  if (text.length <= 2) {
    return '*' + text.charAt(text.length - 1);
  }
  // Timithy Y*on (윤디모데) -> 윤디*데
  if (text.includes('(') || text.includes(')')) {
    const startIndex = text.indexOf('(');
    const endIndex = text.indexOf(')');
    const _name = text.substring(startIndex + 1, endIndex);
    const _middleIndex = Math.floor(_name.length / 2);
    const maskedText = _name.substring(0, _middleIndex) + '*' + _name.substring(_middleIndex + 1);

    return maskedText;
  }

  const middleIndex = Math.floor(text.length / 2);
  const maskedText = text.substring(0, middleIndex) + '*' + text.substring(middleIndex + 1);

  return maskedText;
};

const calculateAge = (birthDate) => {
  const birth = new Date(birthDate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age;
};

const SelectMentee = (props) => {
  const user = useSelector(selectUser);
  const userApplication = useSelector(selectedUserApplication);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { subject, courses } = location.state ?? {};

  const menteeList = useSelector(selectMatchingData);

  const [selectedMentee, setSelectedMentee] = useState({});

  const [selectCount, setSelectCount] = useState(0);
  const [selectList, setSelectList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  // 배남프 현재 기수
  const { currentGeneration } = fetchRemoteConfigValues();

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleSelectMentee = (data) => {
    if (selectCount === 2) {
      return;
    }
    if (selectList.some((select) => select.aid === data.aid)) {
      return;
    }
    setSelectCount(selectCount + 1);
    setSelectList([...selectList, data]);
  };

  const handleUnselectMentee = (aid) => {
    setSelectCount(selectCount - 1);
    setSelectList(selectList.filter((select) => select.aid !== aid));
  };

  const handleModalOpen = (mentee) => {
    setSelectedMentee(mentee);

    setOpen(true);
  };

  const handleRequestMatching = async () => {
    // 2.2. 멘토 application에도 상태값 넣어주기
    const doc = await setDocument(
      FirestorePath.application(userApplication.aid),
      { ...userApplication, matchingStatus: 1 }, //0: 매칭전, 1: 매칭중(멘토가 골랐음), 2:매칭완료
      true
    );
    if (doc === undefined) {
      // console.log('no data');
    } else {
      // console.log('do something');
      // console.log(doc);
    }
    props.navigate('/mypage');
  };

  const handleApplyMatching = () => {
    handleApplyDialogClickOpen();
  };

  const [applyDialogOpen, setApplyDialogOpen] = useState(false);
  const handleApplyDialogClickOpen = () => {
    setApplyDialogOpen(true);
  };

  const handleApplyDialogClose = () => {
    setApplyDialogOpen(false);
  };

  const saveMatchingData = async (e) => {
    // 매칭하기 버튼 클릭 시 동작할 함수
    const menteeList = [...selectList];
    const menteeUidList = Array.from(new Set(menteeList.map((e) => e.uid)));
    const menteeAidList = menteeList.map((e) => e.aid);

    const array = menteeList.map((e) => [e.aid, applicationConverter.toFirestore(e)]);
    const menteeMap = Object.fromEntries(array);

    const { uid, email, userType } = user;
    const {
      aid,
      contact,
      name,
      gender,
      birth,
      availableCourses,
      availableSubjects,
      availableDateTime,
      extraSubjects,
      personality,
    } = userApplication;

    // 1. matching doc 생성
    const object = {
      subject: subject, // 과목 이름
      state: 0, // 매칭 승인 상태- 0:매칭 승인 대기중- 1:매칭 승인- 2:매칭 보류
      appliedAt: serverTimestamp(), // 매칭신청 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
      approvedAt: null, // 매칭승인 시점. - firestore 함수 serverTimestamp 사용(서버시간으로 저장됨)
      approvedMentee: null, // 승인된 멘티 application id
      mentorUid: uid, // 멘토 user id
      mentorAid: aid, // 멘토 application id
      mentorEmail: email, // 멘토 이메일
      mentorContact: contact, // 멘토 연락처(전화번호)
      mentorName: name, // 멘토 이름
      mentorGender: gender, // 멘토 성별
      mentorBirth: birth, // 멘토 생일(나이)
      mentorCourses: availableCourses, // 멘토 수업 과정ex) ['유초등저학년(1~2학년)', '중등', '고등']
      mentorSubjects: availableSubjects, // 멘토 수업 가능 과목
      mentorExtraSubjects: extraSubjects, // 멘토 기타 과목
      mentorDateTime: availableDateTime, // 멘토 수업 가능 시간대
      mentorPersonality: personality, // 멘토 성향
      menteeUidList: menteeUidList, // 멘티들 user id 리스트(쿼리를 위한)
      menteeAidList: menteeAidList, // 멘티들 application id 리스트(쿼리를 위한)
      menteeList: menteeMap, // 멘티 Application Object 리스트
      userType: userType, // user type
      generation: currentGeneration, // 현재 기수
    };

    // console.log(object);

    const docId = await addDocument(FirestorePath.matchings(), object, 'mid', true);

    // console.log(docId);

    // 2. application 에 매칭 진행 상태값 matchingStatus 넣어주기
    menteeList.map(async (mentee) => {
      const newMap = {
        ...mentee.matchingStatusBySubject,
        [subject]: mentee.matchingStatusBySubject[subject] + 1,
      };
      const doc = await setDocument(
        FirestorePath.application(mentee.aid),
        { ...mentee, matchingStatus: increment(1), matchingStatusBySubject: newMap }, //0: 매칭전, 1: 매칭중(멘토가 골랐음), 2:매칭완료
        true
      );
      if (doc === undefined) {
        // console.log('no data');
      } else {
        // console.log('do something');
        // console.log(doc);
      }
    });

    // 2.2. 멘토 application에도 상태값 넣어주기
    const doc = await setDocument(
      FirestorePath.application(userApplication.aid),
      { ...userApplication, matchingStatus: increment(1) }, //0: 매칭전, 1: 매칭중(멘토가 골랐음), 2:매칭완료
      true
    );
    if (doc === undefined) {
      // console.log('no data');
    } else {
      // console.log('do something');
      // console.log(doc);
    }

    setApplyDialogOpen(false);
    props.navigate('/mypage');
  };

  useEffect(() => {
    if (!subject || !courses) {
      return navigate('/');
    } else {
      setIsLoading(false);
      dispatch(fetchMenteeApplication({ subject, courses }));
    }
  }, [subject, courses, dispatch]);

  if (userApplication === undefined) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Root>
      <Box className="flex flex-col justify-center m-auto pt-10 pb-40 w-[62.5rem]">
        <Box className="flex flex-row justify-between pb-10">
          <Box>
            <MyTitle title="매칭하기" />
            <Typography className="!text-xl">
              입력하신 멘토링 정보를 바탕으로 멘티를 추천해드립니다.
            </Typography>
          </Box>
          <Box>
            <img src="/images/matching.png" />
          </Box>
        </Box>
        <Notibox>
          <Typography className="!text-lg pb-2 !font-medium">
            <span className="pr-6">📌</span>
            ‘과목’과 ‘멘토링 가능 연령대’ 일치 여부가 우선적으로 고려되어 멘티가 추천됩니다.
          </Typography>
          <Typography className="!text-lg pb-2 !font-medium">
            <span className="pr-6">📌</span>
            추천된 멘티 중에서 원하는 멘티를 선택하고 매칭 신청을 완료해주세요.
          </Typography>
          <Typography className="!text-lg !font-medium">
            <span className="pr-6">📌</span>한 명의 멘티도 빠짐없이 최적의 매칭이 이루어지기 위해서{' '}
            <span style={{ color: 'red', fontWeight: 'bold' }}>최대 두 명의 매칭 후보를</span>{' '}
            선택해주세요!
          </Typography>
          <Typography className="!text-lg pl-10">
            (최종적으로는 한 과목, 한 명의 멘티와 매칭 됩니다)
          </Typography>
        </Notibox>
        <Stack direction="row" spacing={1} className="mt-4">
          <Chip># {subject}</Chip>
          <Chip>
            #
            {courses?.map(
              (course, index) => ' ' + course + (index !== courses.length - 1 ? ', ' : '')
            )}
          </Chip>
        </Stack>
        <Box className="flex flex-row justify-between items-end py-6">
          <Box>
            <Typography className="!text-2xl pb-1">
              조건이 만족하는 멘티
              <span style={{ color: '#169DC5', paddingLeft: '4px' }}>
                총 {menteeList?.length || 0}명
              </span>
            </Typography>
            {menteeList?.length > 0 && (
              <Typography className="!text-lg !font-medium">
                멘토님께 가장 알맞는 멘티를 발견했어요!
              </Typography>
            )}
          </Box>
          {menteeList?.length > 0 && (
            <Typography className="!text-lg text-[#E4782A]">선택 {selectCount} / 2</Typography>
          )}
        </Box>
        {menteeList?.length > 0 ? (
          <Box className="flex flex-row flex-wrap w-[62.5rem]" rowGap={3} columnGap={2}>
            {menteeList?.map((mentee, index) => {
              const isSelect = selectList.some((select) => select.aid === mentee.aid);
              return (
                <MenteeCard className={isSelect && 'select'} key={index}>
                  <Box
                    className="flex flex-col items-center px-8 py-8"
                    onClick={() => handleSelectMentee(mentee)}
                  >
                    <Avatar
                      sx={{ bgcolor: 'black', width: 165, height: 165 }}
                      alt={mentee.name}
                      src="/broken-image.jpg"
                    />
                    <Typography className="!text-xl pt-4">
                      {maskMiddleCharacter(mentee.name)}
                      <span style={{ color: '#169DC5', paddingLeft: '8px', fontWeight: 500 }}>
                        {mentee.gender === 'F' ? '여' : '남'}
                      </span>
                    </Typography>
                    <Typography className="!text-lg pt-2">
                      만 {calculateAge(mentee.birth)}세
                    </Typography>
                  </Box>
                  <MenteeInfoBox className="pt-4" onClick={() => handleModalOpen(mentee)}>
                    상세보기
                  </MenteeInfoBox>
                </MenteeCard>
              );
            })}
          </Box>
        ) : (
          <Box className="flex flex-col justify-center items-center text-center pt-20">
            <img src="/images/avatar.png" />
            <Typography className="!text-lg !font-medium pt-4 pb-6">
              조건을 만족하는 멘티가 없어요. 😭
              <br />
              다른 과목을 확인해주세요.
              {/* 아래 버튼을 클릭하시면 배남프 본부에서 조율을 도와드리겠습니다. */}
              <br />
            </Typography>
            <Box className="flex flex-row justify-center p-4">
              <Typography className="!text-lg !font-medium py-6">
                모든 과목에 선택 가능한 멘티가 없다면?
              </Typography>
              <RequestButton onClick={() => handleRequestMatching()}>
                기획팀에 조율 요청하기 {' >'}
              </RequestButton>
            </Box>
          </Box>
        )}
        <Box className="flex flex-row justify-center items-center w-full my-12">
          <BackButton onClick={() => props.navigate(-1)}>뒤로가기</BackButton>
        </Box>
      </Box>

      {selectList.length > 0 && (
        <SelectBox>
          <Box className="flex flex-row items-center justify-between w-1/2">
            <Box className="flex flex-row" columnGap={5}>
              {[0, 1].map((index) => {
                const select = selectList[index];
                return (
                  <Box className="relative" key={index}>
                    {select ? (
                      <>
                        <CloseBox onClick={() => handleUnselectMentee(select.aid)}>
                          <CloseIcon />
                        </CloseBox>
                        <Avatar
                          sx={{ bgcolor: 'black', width: 135, height: 135 }}
                          alt={select.name}
                          src="/broken-image.jpg"
                        />
                      </>
                    ) : (
                      <Empty />
                    )}
                  </Box>
                );
              })}
            </Box>
            <Box>
              <ApplyButton onClick={handleApplyMatching}>매칭 신청하기</ApplyButton>
            </Box>
          </Box>
        </SelectBox>
      )}
      <Box className="">
        {/* <Button onClick={handleClickOpen}>dddd</Button> */}
        <MenteeInfoDialog
          open={open}
          handleClose={handleClose}
          mentee={selectedMentee}
          mentor={userApplication}
        />
      </Box>
      <Dialog
        open={applyDialogOpen}
        onClose={handleApplyDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <Box className="flex flex-col justify-center items-center p-10 gap-4 min-w-max">
          <Box className="px-10">
            <DialogTitle id="alert-dialog-title">신청하시겠습니까?</DialogTitle>
          </Box>
          {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
          삭제하기를 클릭하시면 멘티 멘토링 정보가 영구히 삭제됩니다.
          </DialogContentText>
        </DialogContent> */}
          <DialogActions className="flex w-full">
            <Box className="flex-1 flex justify-center items-center w-20 h-14 rounded-xl ">
              <Button variant="text" onClick={handleApplyDialogClose} sx={{ color: 'black' }}>
                취소
              </Button>
            </Box>
            <Box className="flex-1 flex justify-center items-center w-20 h-14 bg-[#E4782A] rounded-xl ">
              <Button variant="text" onClick={saveMatchingData} autoFocus sx={{ color: 'white' }}>
                신청하기
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </Root>
  );
};

const Notibox = styled.div`
  border-radius: 10px;
  border: 2px dashed #e4782a;
  background: #fff2d1;
  padding: 40px;
`;

const Chip = styled.div`
  border-radius: 10px;
  background: #169dc5;
  padding: 10px 20px;
  color: white;
  font-size: 18px;
`;

const MenteeCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  border: 4px solid #e29341;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &.select {
    border: 4px solid #169dc5;
  }
`;

const MenteeInfoBox = styled.div`
  background-color: #e4782a;
  color: #fff;
  padding: 16px 20px;
  width: 100%;
  text-align: center;
`;

const SelectBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e4782a;
  padding: 20px;
  cursor: pointer;
`;

const CloseBox = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ffdcb8;
  border-radius: 50%;
  padding: 5px;
  z-index: 1;
  cursor: pointer;
`;

const Empty = styled.div`
  width: 135px;
  height: 135px;
  border-radius: 50%;
  border: 4px dashed #ddd;
`;

const ApplyButton = styled.button`
  background-color: #000;
  color: #fff;
  padding: 20px 40px;
  border-radius: 10px;
`;

const RequestButton = styled.button`
  /* background-color: #fff; */
  color: #e4782a;
  padding: 1rem 1.5rem;
  /* border-radius: 10px; */
  /* border: 2px solid #e4782a; */
`;

const BackButton = styled.button`
  background-color: #fff;
  color: #e4782a;
  padding: 12px 32px;
  border-radius: 10px;
  border: 2px solid #e4782a;
`;

export default withRouter(SelectMentee);
