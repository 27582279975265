import { Box, Button, Typography } from '@mui/material';
import Root from 'components/Root';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckMentoringInfoExist, logout, selectUser } from 'store/userSlice';
import withRouter from 'utils/withRouter';

import { remoteConfig, getNumber, firebaseAuth } from 'firebase_config';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';

const BeforeIndex = (props) => {
  const { setClicked } = props;

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const checkApplicationExist = useSelector(getCheckMentoringInfoExist);

  // 배남프 현재 기수
  const { currentGeneration } = fetchRemoteConfigValues();

  const logoutOfApp = () => {
    // dispatch to the store with the logout action
    dispatch(logout());
    // sign out function from firebase
    firebaseAuth.signOut();
    props.navigate('/signin');
  };

  return (
    <Root activeMenu="멘토링 정보 입력하기-설문전">
      <Box className="flex justify-center items-center grow max-w-4xl m-auto">
        {false ? (
          <Box className="text-center p-20 md:p-40">
            <Typography variant="h1" fontSize="2rem" fontWeight="bold">
              멘토링 정보 입력 마감
            </Typography>
            <Typography
              component="p"
              color="black"
              variant="body3"
              className="!mt-4"
              fontSize="1rem"
            >
              배남프 {currentGeneration}기 모집이 최종 마감되었습니다
            </Typography>
            <Box className="pt-12">
              <Button
                fullWidth
                className="formButton"
                variant="contained"
                disabled
                onClick={() => setClicked(true)}
              >
                <Typography>작성하기</Typography>
              </Button>
              {checkApplicationExist ? (
                <Button variant="text" onClick={() => props.navigate(-1)}>
                  <Typography fontSize="0.9rem" color="error">
                    뒤로가기
                  </Typography>
                </Button>
              ) : (
                <Button variant="text" onClick={logoutOfApp}>
                  <Typography fontSize="0.9rem" color="error">
                    로그아웃
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          <Box className="text-center p-20 md:p-40">
            <Typography variant="h1" fontSize="2rem" fontWeight="bold">
              {currentGeneration}기 멘토링 정보 입력하기
            </Typography>
            <Typography
              component="p"
              color="black"
              variant="body3"
              className="!mt-4"
              fontSize="1rem"
            >
              {currentGeneration}기 멘토링에 알맞은 멘토와 멘티를 매칭하기 위해
              <br />
              ‘멘토링 정보 입력’이 필요해요
              <br />
              <br />
              작성하기 버튼을 클릭 하셔서 정보를 작성해주세요 :)
              <br />
              <br />* 새로운 기수가 시작되면 멘토링 정보 입력이 다시 시작돼요.
            </Typography>
            <Box className="pt-12">
              <Button
                fullWidth
                className="formButton"
                variant="contained"
                onClick={() => setClicked(true)}
              >
                <Typography>작성하기</Typography>
              </Button>
              {checkApplicationExist ? (
                <Button variant="text" onClick={() => props.navigate(-1)}>
                  <Typography fontSize="0.9rem" color="error">
                    뒤로가기
                  </Typography>
                </Button>
              ) : (
                <Button variant="text" onClick={logoutOfApp}>
                  <Typography fontSize="0.9rem" color="error">
                    로그아웃
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Root>
  );
};

export default withRouter(BeforeIndex);
