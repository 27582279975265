import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import Radio from 'elements/CustomRadio';
import {
  CustomBasicCheckbox as BasicCheckbox,
  CustomSquareCheckbox as SquareCheckbox,
  CustomTimeCheckbox as TimeCheckbox,
} from 'elements/CustomCheckbox';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveApplication, setApplication, setInit } from 'store/applicationSlice';
import { showMessage } from 'store/messageSlice';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

import { firebaseStorage, ref, getDownloadURL, uploadBytes } from 'firebase_config';
import { selectUser, updateUser } from 'store/userSlice';

import { remoteConfig, getNumber, fetchAndActivate } from 'firebase_config';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';
import PriorityBasedSubjectSelection from './PriorityBasedSubjectSelection';
import { generateRandomMenteeData } from './libs';

const SUBJECT_LIST = ['국어', '영어', '수학', '과학', '사회', '역사', '한국어', '기타'];
const BELONG_LIST = ['해외 거주', '온누리 M 센터', '국내 지역교회', '기타'];
const EXTRA_BELONG_LIST = {
  '해외 거주': { title: '해외 거주', placeholder: '거주 국가를 입력해주세요.' },
  '온누리 M 센터': { title: '온누리 M 센터', placeholder: '지역을 입력해주세요.' },
  '국내 지역교회': {
    title: '국내 지역교회',
    placeholder: '교회명/담임목사님 성함을 입력해주세요.',
  },
  기타: { title: '기타', placeholder: '소속을 입력해주세요' },
};
const COURESE_LIST = [
  '유치부',
  '초등-1',
  '초등-2',
  '초등-3',
  '초등-4',
  '초등-5',
  '초등-6',
  '중등-1',
  '중등-2',
  '중등-3',
  '고등-1',
  '고등-2',
  '고등-3',
  '대학이상',
]
const DAYS = ['월', '화', '수', '목', '금', '토', '일'];
const CLASS_TIMES = [
  '00-01',
  '01-02',
  '02-03',
  '03-04',
  '04-05',
  '05-06',
  '06-07',
  '07-08',
  '08-09',
  '09-10',
  '10-11',
  '11-12',
  '12-13',
  '13-14',
  '14-15',
  '15-16',
  '16-17',
  '17-18',
  '18-19',
  '19-20',
  '20-21',
  '21-22',
  '22-23',
  '23-24',
];

const MenteeInfo = ({ rows, step, setStep, actionPrevStep }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [prioritySubjects, setPrioritySubjects] = useState(rows.prioritySubjects || []);

  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState(rows.gender);
  const [belong, setBelong] = useState(rows.belong || 'none');
  const [personality, setPersonality] = useState(rows.personality);
  const [availableSubjects, setAvailableSubjects] = useState(rows.availableSubjects);
  const [availableCourses, setAvailableCourses] = useState(rows.availableCourses);
  const [availableDay, setAvailableDay] = useState([]);
  const [availableTime, setAvailableTime] = useState(rows.availableDateTime);
  const [pledge, setPledge] = useState(rows.pledge);

  const [pray1, setPray1] = useState('');
  const [pray2, setPray2] = useState('');
  const [pray3, setPray3] = useState('');

  // 사진 파일 저장하는 state
  const [imageUpload, setImageUpload] = useState('');
  // 선택한 이미지 이름 저장하는 state
  const [selectedImage, setSelectedImage] = useState('');

  // 배남프 현재 기수
  const { currentGeneration } = fetchRemoteConfigValues();

  // 파일 선택하는 함수
  const selectFile = (file) => {
    if (file !== undefined) {
      setSelectedImage(file.name);
      setImageUpload(file);
    } else {
      setSelectedImage('');
      setImageUpload('');
    }
  };

  // 기도제목 글자수 제한
  const handleCountLenght = (event, type) => {
    const { value } = event.target;
    if (type === 'pray1') {
      if (value.length <= 30) {
        setPray1(value);
      }
    } else if (type === 'pray2') {
      if (value.length <= 30) {
        setPray2(value);
      }
    } else if (type === 'pray3') {
      if (value.length <= 30) {
        setPray3(value);
      }
    }
  };

  const handleSubjectPriorityChange = (subjects) => {
    setPrioritySubjects(subjects);
  };

  const handleRadioChange = (event, type) => {
    if (type === 'gender') {
      setGender(event);
    } else if (type === 'belong') {
      setBelong(event.target.value);
    }
  };

  // 교재 사용에 관한 저작권 안내 체크박스 handle
  const handlePledgeCheckbox = (value) => {
    setPledge(value);
  };

  // 요일 체크 시 해당 요일 모든 시간 체크 on/off
  const handleDayCheckbox = (value, type) => {
    const list = availableDay;

    if (list.includes(value)) {
      setAvailableDay(list.filter((item) => item !== value));
      setAvailableTime(availableTime.filter((item) => item.split('-')[0] !== value));
    } else {
      setAvailableDay([...list, value]);
      const tempList = [];

      CLASS_TIMES.map((time) => {
        const temp = `${value}-${time}`; // ex) 월-09-10
        const isExist = availableTime.includes(temp);

        if (!isExist) {
          tempList.push(temp);
        }
        return 0;
      });

      setAvailableTime([...availableTime, ...tempList]);
    }
  };

  const handleCheckbox = (value, type) => {
    let list;
    if (type === 'availableSubjects') {
      list = availableSubjects;
    } else if (type === 'availableCourses') {
      list = availableCourses;
    } else if (type === 'availableDay') {
      list = availableDay;
    } else if (type === 'availableTime') {
      list = availableTime;
    } else {
      list = personality[type];
    }
    if (list.includes(value)) {
      if (type === 'availableSubjects') {
        setAvailableSubjects(list.filter((item) => item !== value));
      } else if (type === 'availableCourses') {
        setAvailableCourses(list.filter((item) => item !== value));
      } else if (type === 'availableDay') {
        setAvailableDay(list.filter((item) => item !== value));
      } else if (type === 'availableTime') {
        setAvailableTime(list.filter((item) => item !== value));
      } else {
        setPersonality({
          ...personality,
          [type]: list.filter((item) => item !== value),
        });
      }
    } else if (type === 'availableSubjects') {
      setAvailableSubjects([...list, value]);
    } else if (type === 'availableCourses') {
      setAvailableCourses([...list, value]);
    } else if (type === 'availableDay') {
      setAvailableDay([...list, value]);
    } else if (type === 'availableTime') {
      setAvailableTime([...list, value]);
    } else {
      setPersonality({
        ...personality,
        [type]: [...list, value],
      });
    }
  };

  const validateForm = (data) => {
    if (prioritySubjects.length === 0) {
      dispatch(showMessage({ message: '희망 과목을 선택해주세요.', variant: 'error' }));
      return false;
    }

    if (data.name === '') {
      dispatch(showMessage({ message: '이름을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (data.contact === '') {
      dispatch(showMessage({ message: '연락처(이메일)을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (gender === '') {
      dispatch(showMessage({ message: '성별을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (data.birth === '') {
      dispatch(showMessage({ message: '생년월일을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (belong === '') {
      dispatch(showMessage({ message: '소속을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (belong === '기타' && data.extraBelong === '') {
      dispatch(showMessage({ message: '소속을 작성해주세요.', variant: 'error' }));
      return false;
    }
    // if (!availableSubjects.length) {
    //   dispatch(showMessage({ message: '수업 가능 과목을 선택해주세요.', variant: 'error' }));
    //   return;
    // }
    if (!availableCourses.length) {
      dispatch(showMessage({ message: '희망 수업 과정을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!availableTime.length) {
      dispatch(showMessage({ message: '수업 가능 시간을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (imageUpload === '') {
      dispatch(showMessage({ message: '멘티 사진을 선택해주세요.', variant: 'error' }));
      return false;
    }
    if (!pray1.length && !pray2.length && !pray3.length) {
      dispatch(showMessage({ message: '기도제목을 입력해주세요.', variant: 'error' }));
      return false;
    }
    if (!pledge) {
      dispatch(showMessage({ message: '교재 사용 안내를 확인해주세요.', variant: 'error' }));
      return false;
    }
    return true
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.delete('availableDay');
    formData.delete('availableTime');
    formData.delete('mentee');
    formData.delete('mentor');
    formData.delete('pray1');
    formData.delete('pray2');
    formData.delete('pray3');

    if (!validateForm(formData)) return;

    const data = Object.fromEntries(formData.entries());
    // NOTE: 테스트 코드: 멘티 지원서 더미데이터 생성 --
    // const data = generateRandomMenteeData(user.uid, 6, 2)
    // ----------------------------------------
    
    setLoading(true);

    console.log("MENTEE APPLICATION DATA:", data)

    // 로딩 테스트 코드 -----------------------
    // await new Promise((resolve, reject) => {
    //   setTimeout(resolve, 2000);
    // });
    // setLoading(false);
    // ----------------------------------------

    // 파이어베이스 스토리지에 사진 올리기
    const date = new Date().toJSON();
    const photoPath = `mentees/${data.name}_${data.birth.replaceAll('-', '')}_${date
      .replace(/-|:/g, '')
      .replace('.', '')}`;

    const imageRef = ref(firebaseStorage, photoPath);
    if (!imageUpload) {
      dispatch(
        showMessage({
          message: '이미지 업로드에 실패하였습니다. 파일을 확인해주세요.',
          variant: 'error',
        })
      );
      return;
    }
    let imageUrl = '';
    await uploadBytes(imageRef, imageUpload).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {
        imageUrl = url;
      });
    });

    data.gender = gender;
    data.personality = personality;
    data.belong = belong;
    data.availableSubjects = availableSubjects;
    data.availableCourses = availableCourses;
    data.availableDateTime = availableTime;
    data.pledge = pledge;
    data.prayerRequest = [pray1, pray2, pray3].filter((item) => item.length);
    data.photoUrl = imageUrl;
    data.photoPath = photoPath;
    data.uid = user.uid;
    data.email = user.email;
    data.userType = user.userType;
    data.matchingStatus = 0;
    data.matchingStatusBySubject = Object.fromEntries(availableSubjects.map((e) => [e, 0]));
    data.generation = currentGeneration;
    data.prioritySubjects = prioritySubjects;
  

    dispatch(setApplication({ step, data, type: 'mentee' }));
    dispatch(saveApplication({ data, type: 'mentee' })).then((v) => {
      if (v !== undefined) {
        setStep(step + 1);
        dispatch(setInit());
      } else {
        dispatch(showMessage({ message: '정보 저장에 실패했습니다.', variant: 'error' }));
      }

      setLoading(false);
    });
  };

  return (
    <Box>
      <Box className="text-center">
        <Typography variant="h1">멘토링 정보 입력하기</Typography>
        <Typography component="p" color="error" variant="body3" className="!mt-4">
          * 표시된 항목은 필수 입력 항목입니다.
        </Typography>
      </Box>
      <Box className="p-10 md:p-14" component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">이름*</Typography>
            <TextField
              id="name"
              name="name"
              variant="standard"
              placeholder="이름을 입력해주세요."
              InputProps={{ className: 'p-2' }}
              defaultValue={rows.name}
              hiddenLabel
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">생년월일*</Typography>
            <TextField
              id="birth"
              name="birth"
              type="date"
              variant="standard"
              InputProps={{ className: 'p-2' }}
              defaultValue={rows.birth}
              hiddenLabel
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">멘티 연락처(이메일)*</Typography>
            <TextField
              id="contact"
              name="contact"
              variant="standard"
              placeholder="이메일을 입력해주세요."
              InputProps={{ className: 'p-2' }}
              defaultValue={rows.contact}
              hiddenLabel
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">성별*</Typography>
            <Box className="flex !flex-nowrap items-center">
              <Radio
                title="남자"
                value="M"
                checked={gender === 'M'}
                onChange={() => handleRadioChange('M', 'gender')}
              />
              <Radio
                title="여자"
                value="F"
                checked={gender === 'F'}
                onChange={() => handleRadioChange('F', 'gender')}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} columnGap={1} columnSpacing={1}>
            <Typography className="text-left !font-bold pb-2">소속*</Typography>
            <Typography
              className="text-left !text-sm pb-4"
              color={(theme) => theme.palette.error.main}
            >
              소속을 선택해주세요.
            </Typography>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={belong}
                  onChange={(e) => handleRadioChange(e, 'belong')}
                  style={{ padding: 2 }}
                >
                  <MenuItem value="none">
                    <em>선택해주세요.</em>
                  </MenuItem>
                  {BELONG_LIST.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {belong !== 'none' && (
              <>
                <Typography className="text-left !font-bold pb-9">
                  {EXTRA_BELONG_LIST[belong].title} 선택 시
                </Typography>
                <TextField
                  id="extraBelong"
                  name="extraBelong"
                  variant="standard"
                  placeholder={EXTRA_BELONG_LIST[belong].placeholder}
                  InputProps={{ className: 'p-2' }}
                  defaultValue={rows.extraBelong}
                  hiddenLabel
                  fullWidth
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">보호자 이름*</Typography>
            <TextField
              id="guardianName"
              name="guardianName"
              variant="standard"
              placeholder="보호자 이름을 입력해주세요."
              InputProps={{ className: 'p-2' }}
              defaultValue={rows.contact}
              hiddenLabel
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="text-left !font-bold pb-2">보호자 연락처*</Typography>
            <TextField
              id="guardianContact"
              name="guardianContact"
              variant="standard"
              placeholder="첫 연락을 위해 최종 매칭된 멘토에게 전달될 예정입니다."
              InputProps={{ className: 'p-2' }}
              defaultValue={rows.contact}
              hiddenLabel
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12} columnGap={1} columnSpacing={1}>
            <Typography className="text-left !font-bold pb-2">희망 과목*</Typography>
            <Typography
              className="text-left !text-sm pb-4"
              color={(theme) => theme.palette.error.main}
            >
              다중 선택이 가능합니다.
            </Typography>
            <Box className="flex flex-wrap">
              {subjectList.map((item, index) => (
                <BasicCheckbox
                  key={index}
                  name="availableSubjects"
                  label={item}
                  data={availableSubjects}
                  actionChange={() => handleCheckbox(item, 'availableSubjects')}
                />
              ))}
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <PriorityBasedSubjectSelection onChange={handleSubjectPriorityChange} />
          </Grid>
          {availableSubjects.includes('기타') && (
            <Grid item xs={12}>
              <Typography className="text-left !font-bold pb-2">
                희망 과목 작성 (기타 선택 시)
              </Typography>
              <TextField
                id="extraSubjects"
                name="extraSubjects"
                variant="standard"
                placeholder="희망하시는 과목을 입력해주세요."
                InputProps={{ className: 'p-2' }}
                defaultValue={rows.extraSubjects}
                hiddenLabel
                fullWidth
                disabled={!availableSubjects.includes('기타')}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">희망 수업 과정*</Typography>
            <Typography
              className="text-left !text-sm pb-4"
              color={(theme) => theme.palette.error.main}
            >
              다중 선택이 가능합니다.
            </Typography>
            <Box className="flex flex-wrap">
              {COURESE_LIST.map((item, index) => (
                <BasicCheckbox
                  key={index}
                  name="availableCourses"
                  label={item}
                  data={availableCourses}
                  actionChange={() => handleCheckbox(item, 'availableCourses')}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">수업 가능 시간대*</Typography>
            <Typography
              className="text-left !text-sm pb-4"
              color={(theme) => theme.palette.error.main}
            >
              한국 시간을 기준으로 선택해주세요. 가능한 많은 시간을 선택해주셔야 원하는 멘티와
              매칭될 확률이 높아집니다.
            </Typography>
            <Box className="relative">
              {DAYS.map((item, index) => (
                <Box key={index} className="flex items-center gap-2 py-1">
                  <SquareCheckbox
                    key={index}
                    name="availableDay"
                    label={item}
                    data={availableDay}
                    actionChange={() => handleDayCheckbox(item, 'availableDay')}
                  />
                  <Box className="inline-flex w-full overflow-auto">
                    {CLASS_TIMES.map((val, i) => (
                      <TimeCheckbox
                        key={val}
                        name="availableTime"
                        label={val}
                        dataKey={DAYS[index]}
                        data={availableTime}
                        actionChange={() =>
                          handleCheckbox(`${DAYS[index]}-${val}`, 'availableTime')
                        }
                      />
                    ))}
                  </Box>
                  <div className="absolute right-0 w-8 h-11 bg-gradient-to-l from-blue-50"></div>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="flex justify-center items-center px-16 py-10 w-full bg-[#E1F5F8]"
              component="span"
              sx={{ border: '2px dashed #169DC5' }}
            >
              <Typography className="text-left" fontWeight="regular">
                멘티의 사진과 기도제목을 작성해주세요!
                <br />
                매칭될 멘토와 배남프 기획팀이 함께,
                <br />
                하나님 나라를 함께 세워갈 멘티를 위해 계속해서 기도하겠습니다.
                <br />
                <br />
                *사진과 기도제목은 멘토와 기획팀에게만 공유됩니다!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">멘티(학생) 사진*</Typography>
            <Typography
              className="text-left !text-sm pb-4"
              color={(theme) => theme.palette.error.main}
            >
              사진은 2:3(가로:세로) 비율을 맞춰주시면 가장 좋습니다 :)
            </Typography>
            <Box className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <Box className="flex flex-col items-center justify-center pt-5 pb-6 gap-4">
                  <AddPhotoAlternateOutlinedIcon
                    sx={{
                      fontSize: '3rem',
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                  <Box
                    className="flex justify-center items-center py-1/2 px-4 rounded-full border-2 border-[#169DC5]"
                    sx={{
                      fontSize: '0.8rem',
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    사진 선택하기
                  </Box>
                  <Typography>{selectedImage}</Typography>
                </Box>
                <input
                  id="dropzone-file"
                  accept="image/x-png,image/jpeg"
                  type="file"
                  className="hidden"
                  onChange={(event) => selectFile(event.target.files[0])}
                />
              </label>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography className="text-left !font-bold pb-2">기도제목*</Typography>
            <Box>
              <TextField
                id="pray1"
                name="pray1"
                variant="outlined"
                placeholder="30자 이내로 작성해주세요."
                InputProps={{ className: 'p-2' }}
                value={pray1}
                onChange={(e) => handleCountLenght(e, 'pray1')}
                hiddenLabel
                fullWidth
              />
              <Typography className="text-right !text-sm pb-2">{pray1.length} / 30자</Typography>
            </Box>
            <Box>
              <TextField
                id="pray2"
                name="pray2"
                variant="outlined"
                placeholder="30자 이내로 작성해주세요."
                InputProps={{ className: 'p-2' }}
                value={pray2}
                onChange={(e) => handleCountLenght(e, 'pray2')}
                hiddenLabel
                fullWidth
              />
              <Typography className="text-right !text-sm pb-2">{pray2.length} / 30자</Typography>
            </Box>
            <Box>
              <TextField
                id="pray3"
                name="pray3"
                variant="outlined"
                placeholder="30자 이내로 작성해주세요."
                InputProps={{ className: 'p-2' }}
                value={pray3}
                onChange={(e) => handleCountLenght(e, 'pray3')}
                hiddenLabel
                fullWidth
              />
              <Typography className="text-right !text-sm pb-2">{pray3.length} / 30자</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="flex flex-col gap-7 justify-center items-center px-16 py-10 w-full bg-[#FFDCB8]"
              component="span"
              sx={{ border: '2px dashed #E4782A' }}
            >
              <Typography className="text-center" variant="h1">
                교재 사용에 관한 저작권 안내
              </Typography>
              <Typography className="text-left" fontWeight="regular">
                아래와 같은 행위를 할 경우 법적인 문제가 발생할 수 있음을 알려드립니다.
                <br />
                <br />
                • 배남프 수업 이외의 목적으로 사용하는 경우
                <br />
                • 개인의 영리를 위해 사용하는 경우
                <br />
                • 타인과 공유하는 경우
                <br />
              </Typography>
              <Box className="flex items-center">
                <Checkbox
                  checked={pledge}
                  onChange={() => handlePledgeCheckbox(!pledge)}
                  color="primary"
                />
                <Typography variant="body3">위 내용을 확인했습니다 (필수)</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className="flex flex-row items-center justify-center pt-24">
          <Button
            fullWidth
            className="formButton"
            variant="contained"
            color="inherit"
            onClick={actionPrevStep}
          >
            취소
          </Button>
          <Button fullWidth className="formButton" type="submit" variant="contained">
            완료하기
          </Button>
        </Box>
      </Box>
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <Box className="flex justify-center items-center p-8 bg-white rounded-lg">
          <CircularProgress color="inherit" />
        </Box>
      </Backdrop>
    </Box>
  );
};

export default MenteeInfo;
