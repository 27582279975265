import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { where } from 'firebase/firestore';
import InfoBox from 'pages/my_page/components/InfoBox';
import MyInfo from 'pages/my_page/components/MyInfo';
import React, { useEffect, useState } from 'react';
import FirestorePath from 'services/firebase_path';
import { getCollectionFuture } from 'services/firebase_services';
import * as XLSX from 'xlsx';
import PlanningWidget from './widgets/Planning';
import LessonLogWidget from './widgets/LessonLog';
import { fetchPlanning } from 'store/planningSlice';
import { useDispatch } from 'react-redux';
import { adminAccountList } from 'constants';

///
/// 어드민 > 수업관리 > 수업진행현황
const ClassProgressStatus = () => {
  const dispatch = useDispatch();

  /// false 는 오름차순(asc), true 는 내림차순(desc)
  const [columnSort, setColumnSort] = useState({
    mentorEmail: false,
    mentorName: false,
    subject: false,
  });
  const [graphData, setGraphData] = useState([]);

  /// 상세보기 버튼 클릭 여부 상태값
  const [isDetailShow, setIsDetailShow] = useState(false);

  const [matchingStatus, setMatchingStatus] = useState({});
  const [accountNum, setAccountNum] = useState(0);
  let rowIndex = 0;

  useEffect(() => {}, [graphData]);

  const columns = [
    { id: 'no.', label: 'no.', minWidth: 10, align: 'left' },
    { id: 'mentorEmail', label: '멘토이메일', minWidth: 70, align: 'left' },
    { id: 'mentorName', label: '멘토이름', minWidth: 70, align: 'left' },
    { id: 'subject', label: '수업과목', minWidth: 70, align: 'left' },
    { id: 'isPlanningExist', label: '수업계획서', minWidth: 70, align: 'left' },
    { id: 'isLessonLogExist', label: '수업일지', minWidth: 70, align: 'left' },
  ];

  async function loadData() {
    const userDocss = await getCollectionFuture(
      FirestorePath.matchings(),
      (data, docId) => data.data(),
      [where('approvedAt', '!=', null)]
    );

    // 테스트 계정 멘토는 지워주기
    const userDocs = userDocss.filter(
      (doc) =>
        doc.mentorEmail !== 'bhgt9510@naver.com' &&
        doc.mentorEmail !== 'sorahyun83@gmail.com' &&
        doc.mentorEmail !== 'ljeongmin0908@gmail.com' &&
        doc.mentorEmail !== 'dpwls2588@gmail.com'
    );

    setAccountNum(userDocs.length);

    /// 수업계획서 불러오기
    const planningDocs = await getCollectionFuture(
      FirestorePath.plannings(),
      (data, docId) => data.data(),
      []
    );

    /// 수업일지 불러오기
    const lessonLogDocs = await getCollectionFuture(
      FirestorePath.lessonlogs(),
      (data, docId) => data.data(),
      []
    );

    if (userDocs === undefined) {
      console.log('docs undefined');
      setGraphData([]);
    } else if (userDocs.length === 0) {
      console.log('no data');
      setGraphData([]);
    } else {
      console.log('do something');

      var tempList = [];

      userDocs.map((data) => {
        // 수업계획서 존재 여부 업데이트
        var planningList = planningDocs.filter((planning) => planning.uid === data.mentorUid);
        var isPlanningExist = planningList.length !== 0;

        // 수업일지 존재 여부 업데이트
        var lessonLogList = lessonLogDocs.filter((lessonLog) => lessonLog.uid === data.mentorUid);
        var isLessonLogExist = lessonLogList.length !== 0;
        var lessonLogLength = 0;

        if (lessonLogList.length) {
          var keysData = { ...lessonLogList[0] };
          delete keysData['aid'];
          delete keysData['uid'];

          lessonLogLength = Object.keys(keysData).length;
        }

        // 테이블 데이터 업데이트
        var temp = {
          ...data,
          isPlanningExist: isPlanningExist,
          isLessonLogExist: isLessonLogExist,
          lessonLogLength: lessonLogLength,
        };

        tempList.push(temp);
      });

      // 테스트 계정 멘티는 지워주기
      // TODO: 비슷한 코드 다 변경해주기
      const temp = tempList.filter((doc) => {
        return !adminAccountList.includes(doc['mentorEmail']);
      });

      /// < : 오름차순(asc) 정렬, >: 내림차순(desc) 정렬
      const result = temp.sort((a, b) => (a['mentorName'] < b['mentorName'] ? -1 : 1));
      // console.log(result);
      setGraphData([...result]);
    }
  }

  const columnList = [
    'mentorEmail',
    'mentorName',
    'subject',
    'isPlanningExist',
    'isLessonLogExist',
  ];

  // 데이터 전처리 함수: '수업코스' 배열을 문자열로 변환
  function preprocessData(data) {
    var a = data.map((item) => {
      return {
        mentorEmail: item.mentorEmail,
        mentorName: item.mentorName,
        subject: item.subject,
        // prayerRequest: item.prayerRequest.toString(), // 배열의 모든 항목을 하나의 문자열로 합침
      };
    });

    return a;
  }

  // Excel 파일로 내보내기 함수
  function exportToExcel(data, columnList) {
    // 데이터 전처리
    const processedData = preprocessData(data);

    // 현재 시간 가져오기 (YYYYMMDD_HHMMSS 형식)
    const now = new Date();
    const fileNameDatePart = `${now.getFullYear()}${(now.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}_${now
      .getHours()
      .toString()
      .padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;

    // 워크시트 생성
    const worksheet = XLSX.utils.json_to_sheet(processedData, { header: columnList });

    // 워크북 생성 및 워크시트 추가
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Excel 파일 생성 (브라우저에서 다운로드)
    XLSX.writeFile(workbook, `classes_${fileNameDatePart}.xlsx`);
  }

  const createSortHandler = (property) => (event) => {
    const ids = [
      'no.',
      'mentorEmail',
      'mentorName',
      'subject',
      'isPlanningExist',
      'isLessonLogExist',
    ];

    if (ids.includes(event.target.id) && event.target.id != 'no.') {
      const id = event.target.id;

      /// < : 오름차순(asc) 정렬, >: 내림차순(desc) 정렬
      const result = graphData.sort((a, b) =>
        columnSort[id] ? (a[id] > b[id] ? -1 : 1) : a[id] < b[id] ? -1 : 1
      );

      setColumnSort({ ...columnSort, [id]: !columnSort[id] });
      setGraphData([...result]);
    }
  };

  const [selectedApplication, setSelectedApplication] = useState({});

  function handleRowButtonClick(uid, aid, name, gender, birth, email, contact) {
    setIsDetailShow(true);

    // 사용자 기본정보
    var userData = {
      uid: uid,
      aid: aid,
      name: name,
      gender: gender,
      birth: birth,
      email: email,
      contact: contact,
      matchingStatus: 1,
    };

    setSelectedApplication(userData);
  }

  return (
    <Box className="flex flex-col gap-8 mb-8">
      <Box className="flex flex-row gap-8 mb-8">
        <Button variant="contained" onClick={() => loadData()}>
          불러오기
        </Button>
        {/* TODO: 기수별 구분자(generation) 모든 DB에 넣고 모든 페이지 잘 나오는지 테스트하기 */}
        {/*       ㄴ기수별로 조회가 필요한 페이지 어떤것들이 있는지 확인해서 작업하기 */}
        {/* TODO: 엑셀 export 기능 확인 */}
        <Button variant="contained" onClick={() => loadData()}>
          엑셀 export
        </Button>
      </Box>

      {graphData.length ? (
        <Box className="flex flex-col gap-4 ">
          <Box className="flex gap-4 pb-4">
            <Box>수업 총 {graphData.length}개</Box>
            {/* TODO: 전체 완료 수계,수일 개수 / 전체 총개수 */}
            {/* TODO: 수업별 완료 수계,수일 개수 / 수업별 총개수 */}
            {/* TODO: 조별 완료 수계,수일 개수 / 조별 총 개수 */}
          </Box>
          <Paper elevation={10} sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ height: '55vh' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          id={column.id}
                          hideSortIcon={true}
                          active={column.id != 'no.' ? true : false}
                          direction={columnSort[column.id] ? 'asc' : 'desc'}
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>자세히보기</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {graphData.map((data, dataIndex) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={data.mid + dataIndex}>
                        <TableCell align="left">{++rowIndex}</TableCell>
                        <TableCell align="left">{data.mentorEmail}</TableCell>
                        <TableCell align="left">{data.mentorName}</TableCell>
                        <TableCell align="left">{data.subject}</TableCell>
                        <TableCell align="left">
                          {data.isPlanningExist ? '✅ 작성완료' : '미작성'}
                        </TableCell>
                        <TableCell align="left">
                          {data.isLessonLogExist
                            ? `✅ 작성중(${data.lessonLogLength}회차)`
                            : '미작성'}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleRowButtonClick(
                                data.mentorUid,
                                data.mentorAid,
                                data.mentorName,
                                data.mentorGender,
                                data.mentorBirth,
                                data.mentorEmail,
                                data.mentorContact
                              )
                            }
                          >
                            자세히보기
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {isDetailShow ? (
            <Box className="flex flex-col gap-4 pt-4">
              <Box>기본정보 </Box>
              <Box className="flex border-2">
                <InfoBox title="기본정보">
                  <MyInfo application={selectedApplication} />
                </InfoBox>
              </Box>
              <Box>수업계획서 </Box>
              <Box className="flex border-2">
                <PlanningWidget
                  uid={selectedApplication.uid}
                  name={selectedApplication.name}
                  contact={selectedApplication.contact}
                />
              </Box>
              <Box>수업일지 </Box>
              <Box className="flex border-2">
                <LessonLogWidget uid={selectedApplication.uid} />
              </Box>
            </Box>
          ) : (
            <Box></Box>
          )}
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default ClassProgressStatus;
