import { Box, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import { remoteConfig, getNumber, getString, fetchAndActivate } from 'firebase_config';
import { fetchRemoteConfigValues } from 'utils/remote_config/RemoteConfig';

const Vacation = () => {
  const emojiList = [
    '🎁',
    '🎀',
    '🧸',
    '🎉',
    '🎈',
    '🪩',
    '🇰🇷',
    '⚽️',
    '🎲',
    '♟️',
    '🎸',
    '🚴',
    '🛹',
    '🛝',
    '🥋',
    '☂️',
    '🌸',
    '✨',
    '⭐️',
    '🌼',
    '🍄',
    '🍀',
  ];

  // 배남프 현재 기수
  const { currentGeneration } = fetchRemoteConfigValues();

  return (
    <Box className="flex flex-col flex-1 justify-center items-center text-center">
      <Typography variant="h1" fontSize="4rem">
        {emojiList[Math.floor(Math.random() * emojiList.length)]}
      </Typography>
      {
        <Box>
          <Typography variant="h1">
            이번 기수 배남프가 종료되었습니다 :)
            <br />
            <br />
          </Typography>
          <Typography variant="h2" fontWeight={'regular'}>
            다음 기수에 다시 만나요!
          </Typography>
        </Box>
      }
    </Box>
  );
};

export default Vacation;
