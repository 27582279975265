import { Box, Button, Typography } from '@mui/material';
import Root from 'components/Root';
import { selectUser, getCheckMentoringInfoExist } from 'store/userSlice';
import { useSelector } from 'react-redux';
import Mentor from './Mentor';
import Mentee from './Mentee';
import { useEffect, useState } from 'react';
import BeforeIndex from './components/BeforeIndex';
import withRouter from 'utils/withRouter';
import { Navigate } from 'react-router-dom';

const Applications = (props) => {
  const user = useSelector(selectUser);
  const checkApplicationExist = useSelector(getCheckMentoringInfoExist);

  const [clicked, setClicked] = useState(false);
  const [mentoringInfoExist, setMentoringInfoExist] = useState(false);

  useEffect(() => {
    if (user) {
      setMentoringInfoExist(checkApplicationExist);
    }
  }, [user]);

  if (user) {
    if (!mentoringInfoExist || user.userType === 'mentee') {
      if (clicked) {
        return (
          <Root activeMenu="멘토링 정보 입력하기">
            <Box className="flex flex-col items-center">
              {user.userType === 'mentor' ? <Mentor /> : <Mentee />}
            </Box>
          </Root>
        );
      } else {
        return <BeforeIndex setClicked={setClicked} />;
      }
    }
    return <Navigate to="/mypage" />;
  }

  return <Navigate to="/" />;
};

export default withRouter(Applications);
