import React, { useState } from 'react';
import { Box, Typography, FormControl, Select, MenuItem } from '@mui/material';

const SUBJECT_LIST = ['국어', '영어', '수학', '과학', '사회', '역사', '한국어', '기타'];

const PriorityBasedSubjectSelection = ({ onChange }) => {
  const [priorities, setPriorities] = useState({
    first: '',
    second: '',
  });

  const handlePriorityChange = (event, priority) => {
    const newPriorities = { ...priorities, [priority]: event.target.value };
    setPriorities(newPriorities);
    // onChange(Object.values(newPriorities).filter(Boolean));
    onChange(newPriorities);
  };

  const getAvailableSubjects = (priority) => {
    const selectedSubjects = Object.values(priorities);
    return SUBJECT_LIST.filter(
      (subject) => subject === priorities[priority] || !selectedSubjects.includes(subject)
    );
  };

  return (
    <Box>
      <Typography className="text-left !font-bold pb-2">희망 과목 (우선순위)*</Typography>
      <Typography className="text-left !text-sm pb-4" color="error">
        최대 {priorities.length}개까지 우선순위를 정해 선택해주세요.
      </Typography>
      {Object.keys(priorities).map((priority, index) => (
        <FormControl key={priority} fullWidth size="small" className="mb-4">
          <Typography className="text-left !text-sm pb-2">{index + 1}순위 과목</Typography>
          <Select
            value={priorities[priority]}
            onChange={(e) => handlePriorityChange(e, priority)}
            displayEmpty
          >
            <MenuItem value="">
              <em>선택해주세요</em>
            </MenuItem>
            {getAvailableSubjects(priority).map((subject) => (
              <MenuItem key={subject} value={subject}>
                {subject}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
    </Box>
  );
};

export default PriorityBasedSubjectSelection;
