import { Box, Button, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/userSlice';

const Apply = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  return (
    <Box className="flex flex-col justify-center items-center w-full py-12 md:py-36 max-w-4xl text-center">
      <Typography className="pb-5" variant="h1" fontSize="2.25rem">
        배남프 신청하기
      </Typography>
      <Typography className="pb-8 !font-medium" variant="body1">
        <span className="text-orange-600">멘토 신청</span>은 신청하기 버튼을 클릭하면 신청
        구글폼으로 이동합니다.
        <br />
        정보를 입력하고 제출해주세요 :)
      </Typography>
      {/* <Typography className="pb-8 !font-medium" variant="body1">
        <span className="text-orange-600">멘토 신청</span>이 마감되었습니다.
        <br />
        다음 기수에 뵐 수 있길 기대하겠습니다 :)
      </Typography> */}
      <Typography className="pb-16 !font-medium" variant="body1">
        <span className="text-orange-600">멘티 신청</span>은 홈페이지 가입 후 내 정보에서 신청이
        가능합니다.
        <br />
        이전에 로그인 한 적이 있다면, 로그인 후 내 정보에서 신청해주세요.
      </Typography>
      <Box className="flex sm:flex-row flex-col gap-10">
        <Button
          className="flex flex-row gap-2 w-82 h-16"
          variant="outlined"
          color="primary"
          sx={{ padding: '1.5rem 3rem', color: 'black' }}
          onClick={() => {
            window.open('https://forms.gle/7zfVJ5JCocP8ph2x6', '_blank');
          }}
          // disabled
        >
          <Typography variant="body1">
            👩‍🏫 <span className="pl-1">멘토 신청하기</span>
          </Typography>
          <EastIcon fontSize="small" />
        </Button>
        <Button
          className="flex flex-row gap-2 w-82 h-16"
          variant="outlined"
          color="primary"
          sx={{ padding: '1.5rem 3rem', color: 'black' }}
          onClick={() => {
            user?.userType === 'mentee' ? navigate('/mentoring-info') : navigate('/bnp-sign-up');
          }}
          // disabled
        >
          <Typography variant="body1">
            🧑‍🎓 <span className="pl-1">멘티 신청하기</span>
          </Typography>
          <EastIcon fontSize="small" />
        </Button>
      </Box>
      {/* <Typography className="py-4" variant="body1" color={(theme) => theme.palette.error.main}>
        멘토/멘티 모집이 마감되었습니다
      </Typography> */}
    </Box>
  );
};

export default Apply;
