import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/userSlice';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FirestorePath from 'services/firebase_path';
import { deleteDocument } from 'services/firebase_services';
import { firebaseStorage, ref } from 'firebase_config';
import { deleteObject } from 'firebase/storage';
import { useState } from 'react';
import { showMessage } from 'store/messageSlice';

const MyInfo = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const bgColor = user.userType === 'mentee' ? '#F5F5F5' : 'white';
  const { application } = props;
  const { aid, name, gender, birth, email, photoPath } = application;
  const [open, setOpen] = useState(false);

  const today = new Date();
  const birthDate = new Date(birth);
  const age = today.getFullYear() - birthDate.getFullYear();

  const lastName = name.replaceAll(' ', '').substring(0, 1);
  const firstName = name.replaceAll(' ', '').substring(1);
  const profileName = firstName.length > 4 ? lastName : firstName;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteMyInfo = () => {
    if (application.matchingStatus === undefined || application.matchingStatus === 0) {
      async function deleteApplication(aid) {
        const isCompleted = await deleteDocument(FirestorePath.application(aid));
        // if (isCompleted) {
        //   console.log('delete complete');
        // } else {
        //   console.log('delete fail');
        // }
      }

      async function deleteStorage(photoPath) {
        const child = photoPath.split('/')[1];
        const desertRef = ref(firebaseStorage, `mentees/${child}`);

        // Delete the file
        await deleteObject(desertRef)
          .then(() => {
            // File deleted successfully
            console.log('성공');
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
          });
      }

      deleteApplication(aid);
      deleteStorage(photoPath);
      //TODO: setOpen(false) 실행 안되는 문제 해결
      setOpen(false);
    } else {
      dispatch(
        showMessage({
          message: '해당 멘티는 매칭이 시작되어 삭제할 수 없습니다.',
          variant: 'error',
        })
      );
    }
  };

  return (
    <Box className="flex flex-row rounded-2xl group/myInfo" sx={{ backgroundColor: bgColor }}>
      <Box className="flex items-center p-5">
        <Box
          className="flex justify-center items-center w-28 h-28 rounded-full"
          sx={{ backgroundColor: '#' + Math.round(Math.random() * 0xffffff).toString(16) }}
        >
          <Typography fontSize="1.75rem" color="white">
            {profileName}
          </Typography>
        </Box>
      </Box>
      <Box className="flex flex-1 md:flex-row flex-col py-14 pl-5">
        <Box className="flex-1 flex-col justify-between">
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body3" fontWeight="bold">
                  이름(성별)
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Box className="flex gap-1">
                  <Typography variant="body3">{name}</Typography>
                  <Typography
                    variant="body3"
                    fontWeight="bold"
                    color={(theme) =>
                      gender === 'M' ? theme.palette.primary.main : theme.palette.error.main
                    }
                  >
                    {gender === 'M' ? '남' : '여'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="flex">
            <Grid container wrap="nowrap" spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body3" fontWeight="bold">
                  이메일
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body3" sx={{ overflowWrap: 'break-word' }}>
                  {email}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="flex-1 flex-col">
          <Box className="flex">
            <Grid container spacing={0}>
              <Grid item xs={5}>
                <Typography variant="body3" fontWeight="bold">
                  생년월일(나이)
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body3">{`${birth.replaceAll('-', '.')}(${age}세)`}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {user.userType === 'mentee' ? (
        <Box className="lg:invisible visible flex justify-center items-center group/delete group-hover/myInfo:visible px-4">
          <IconButton
            className="flex justify-center items-center w-12 h-12"
            onClick={handleClickOpen}
          >
            <DeleteOutlineIcon className="text-[#7d7d7d] group-hover/delete:text-[#E4782A]" />
          </IconButton>
        </Box>
      ) : (
        <></>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <Box className="flex flex-col justify-center items-center p-10 gap-4 min-w-max">
          <Box className="px-10">
            <DialogTitle id="alert-dialog-title">삭제하시겠습니까?</DialogTitle>
          </Box>
          {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
          삭제하기를 클릭하시면 멘티 멘토링 정보가 영구히 삭제됩니다.
          </DialogContentText>
        </DialogContent> */}
          <DialogActions className="flex w-full">
            <Box className="flex-1 flex justify-center items-center w-20 h-14 rounded-xl ">
              <Button variant="text" onClick={handleClose} sx={{ color: 'black' }}>
                취소
              </Button>
            </Box>
            <Box className="flex-1 flex justify-center items-center w-20 h-14 bg-[#E4782A] rounded-xl ">
              <Button variant="text" onClick={deleteMyInfo} autoFocus sx={{ color: 'white' }}>
                삭제하기
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default MyInfo;
