import { fetchAndActivate, getNumber, getString, remoteConfig } from 'firebase_config';

/// Remote Config에서 값을 가져오는 함수
export const fetchRemoteConfigValues = () => {
  try {
    // 현재 기수
    const currentGeneration = getNumber(remoteConfig, 'current_generation');

    // 현재 기수 매칭 시작 날짜
    const openDatetimeMatching = getString(remoteConfig, 'open_datetime_matching');

    // 현재 기수 매칭 종료 날짜
    const closeDatetimeMatching = getString(remoteConfig, 'close_datetime_matching');

    // 현재 기수 전체 시작 날짜
    const startDatetimeGenerationDate = getString(remoteConfig, 'start_current_generation');

    // 현재 기수 전체 종료 날짜
    const endDatetimeGenerationDate = getString(remoteConfig, 'end_current_generation');

    // 현재 기수 수업계획서 작성 시작 날짜
    const openDatetimeSyllabus = getString(remoteConfig, 'open_datetime_syllabus');

    // 각 값을 변환 및 처리 (예시: 날짜로 변환)
    const startGenerationDate = new Date(startDatetimeGenerationDate);
    const endGenerationDate = new Date(endDatetimeGenerationDate);
    const closeMatchingDate = new Date(closeDatetimeMatching);
    const openMatchingDate = new Date(openDatetimeMatching);
    const openSyllabusDate = new Date(openDatetimeSyllabus);

    // 원하는 값을 반환 또는 콘솔 출력
    // console.log('Current Generation:', currentGeneration);
    // console.log('Start Current Generation Date:', startGenerationDate);
    // console.log('End Current Generation Date:', endGenerationDate);
    // console.log('Close Matching Date:', closeMatchingDate);
    // console.log('Open Matching Date:', openMatchingDate);
    // console.log('Open Syllabus Date:', openSyllabusDate);

    return {
      currentGeneration,
      openMatchingDate,
      closeMatchingDate,
      startGenerationDate,
      endGenerationDate,
      openSyllabusDate,
    };
  } catch (error) {
    console.error('Error fetching remote config:', error);
  }
};
