class UserModel {
  constructor(name, email, uid, userType, mentoringInfoExist, gender, phone, birth) {
    // TODO: Validation 넣기
    this.name = name;
    this.email = email;
    this.uid = uid;
    this.userType = userType;
    this.mentoringInfoExist = mentoringInfoExist;
    this.gender = gender;
    this.phone = phone;
    this.birth = birth;
  }

  toString() {
    return `${this.name}, ${this.email},  ${this.uid}, ${this.userType}, ${this.mentoringInfoExist}`;
  }
}

// Firestore data converter
const userConverter = {
  toFirestore: (user) => {
    return {
      name: user.name,
      email: user.email,
      uid: user.uid,
      userType: user.userType,
      mentoringInfoExist: user.mentoringInfoExist,
      gender: user.gender,
      phone: user.phone,
      birth: user.birth,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new UserModel(
      data.name,
      data.email,
      data.uid,
      data.userType,
      data.mentoringInfoExist ?? null,
      data.gender ?? null,
      data.phone ?? null,
      data.birth ?? null,
    );
  },
};

export default userConverter;
